.GatheringFeedbackPresentation-module__slideContainer_GzhqzG__ {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.GatheringFeedbackPresentation-module__overlay_GzhqzG__ {
  pointer-events: none;
  background-color: #000000e6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.GatheringFeedbackPresentation-module__slideImage_GzhqzG__ {
  width: 100%;
}

.GatheringFeedbackPresentation-module__loading_GzhqzG__ {
  color: #fff;
  font-size: 48px;
}

.GatheringFeedbackPresentation-module__gatheringFeedbackContainer_GzhqzG__ {
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.GatheringFeedbackPresentation-module__gatheringFeedbackTextContainer_GzhqzG__ {
  margin-top: 50px;
}

.GatheringFeedbackPresentation-module__gatheringFeedbackText_GzhqzG__ {
  color: #fff;
  font-size: 18px;
}
