.Login-module__container_ed7Z1G__ {
  box-sizing: border-box;
  place-content: center;
  height: 100%;
  padding-top: 100px;
  display: flex;
  overflow-y: scroll;
}

.Login-module__instructLogoContainer_ed7Z1G__ {
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.Login-module__loginContainer_ed7Z1G__ {
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  display: flex;
}

.Login-module__errorAlert_ed7Z1G__ {
  margin-bottom: 26px;
}

.Login-module__instructLogo_ed7Z1G__ {
  width: 50px;
}

.Login-module__welcomeMsgContainer_ed7Z1G__ {
  margin-bottom: 40px;
}

.Login-module__welcomeMsg_ed7Z1G__ {
  text-align: center;
  margin-top: 0;
}

.Login-module__loginForm_ed7Z1G__ {
  margin-bottom: 100px;
}

.Login-module__passwordFormItem_ed7Z1G__ {
  margin-bottom: 6px;
}

.Login-module__forgotPasswordBtn_ed7Z1G__ {
  margin-bottom: 32px;
  padding: 0;
}

.Login-module__loginBtn_ed7Z1G__ {
  width: 100%;
}
