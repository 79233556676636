.PracticeKeySteps-module__loadingSpinnerContainer_REFxaa__ {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 420px;
  display: flex;
}

.PracticeKeySteps-module__loadingSpinner_REFxaa__ {
  color: #aaa;
  font-size: 48px;
}

.PracticeKeySteps-module__slideContainer_REFxaa__ {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.PracticeKeySteps-module__hidden_REFxaa__ {
  visibility: hidden;
}

.PracticeKeySteps-module__noDisplay_REFxaa__ {
  display: none;
}

.PracticeKeySteps-module__overlay_REFxaa__ {
  z-index: 1;
  background-color: #000000e6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.PracticeKeySteps-module__slideImage_REFxaa__ {
  width: 100%;
  height: auto;
}

.PracticeKeySteps-module__yourTurnContainer_REFxaa__ {
  z-index: 1;
  text-align: center;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  position: relative;
}

#PracticeKeySteps-module__yourTurnTitle_REFxaa__ {
  color: #fff;
  text-align: left;
  align-self: flex-start;
  margin-bottom: 18px;
}

#PracticeKeySteps-module__yourTurnDescription_REFxaa__ {
  color: #fff;
  text-align: left;
  margin-top: 0;
  margin-bottom: 50px;
  padding-left: 12px;
  font-size: 16px;
}

.PracticeKeySteps-module__keyStep_REFxaa__ {
  margin-bottom: 4px;
}
