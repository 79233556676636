:root {
  --primary-color: #2e4a8b;
  --primary-color-hover: #4b6499;
  --primary-color-active: #1d2f66;
  --primary-color-dark: #2e498b;
  --primary-color-light: #f0f3fb;
  --secondary-color: #fc0;
}

.PresentationPractice-module__container_bRt9oW__ {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.PresentationPractice-module__horizontalDivider_bRt9oW__ {
  border-block-start-color: #f0f0f0;
  min-width: 200%;
  margin: 16px -50px;
}

.PresentationPractice-module__slideContainer_bRt9oW__ {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.PresentationPractice-module__slideHidden_bRt9oW__ {
  display: none;
}

.PresentationPractice-module__slideVisible_bRt9oW__ {
  pointer-events: none;
}

.PresentationPractice-module__overlay_bRt9oW__ {
  pointer-events: none;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PresentationPractice-module__audioIcon_bRt9oW__ {
  color: #fff;
  font-size: 44px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PresentationPractice-module__audioText_bRt9oW__ {
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: calc(50% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.PresentationPractice-module__beginRecordingBtn_bRt9oW__ {
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 135px;
  height: 135px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PresentationPractice-module__beginRecordingBtn_bRt9oW__:hover {
  background-color: var(--primary-color-hover);
  cursor: pointer;
}

.PresentationPractice-module__beginRecordingBtn_bRt9oW__:active {
  background-color: var(--primary-color-active);
}

.PresentationPractice-module__countDownContainer_bRt9oW__ {
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 135px;
  height: 135px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PresentationPractice-module__countDownText_bRt9oW__ {
  text-align: center;
  color: #fff;
  font-size: 70px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PresentationPractice-module__slideButton_bRt9oW__ {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.PresentationPractice-module__slideButtonLeft_bRt9oW__ {
  left: 20px;
}

.PresentationPractice-module__slideButtonRight_bRt9oW__ {
  right: 20px;
}

.PresentationPractice-module__videoRecorderContainer_bRt9oW__ {
  text-align: center;
  margin-top: 15px;
  margin-right: 15px;
  position: absolute;
  right: 0;
}

.PresentationPractice-module__videoStreamContainer_bRt9oW__ {
  border-radius: 75px;
  width: 140px;
  height: 140px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.PresentationPractice-module__videoStream_bRt9oW__ {
  object-fit: cover;
  object-position: center;
  width: 200px;
  height: 200px;
  bottom: 50%;
  right: 50%;
  transform: scaleX(-1);
}

.PresentationPractice-module__videoStreamHidden_bRt9oW__ {
  display: none;
}
