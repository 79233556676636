.CompletedPresentationPractice-module__slideContainer_iM5_aa__ {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.CompletedPresentationPractice-module__overlay_iM5_aa__ {
  pointer-events: none;
  background-color: #000000e6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.CompletedPresentationPractice-module__slideImage_iM5_aa__ {
  width: 100%;
}

.CompletedPresentationPractice-module__completedSessionWrapper_iM5_aa__ {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: absolute;
}

.CompletedPresentationPractice-module__completedSessionContainer_iM5_aa__ {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

#CompletedPresentationPractice-module__completedSessionTitle_iM5_aa__ {
  color: #fff;
  align-self: flex-start;
}

#CompletedPresentationPractice-module__completedSessionBody_iM5_aa__ {
  color: #fff;
  align-self: flex-start;
  font-size: 16px;
}

.CompletedPresentationPractice-module__buttonContainer_iM5_aa__ {
  justify-content: center;
  display: flex;
}

.CompletedPresentationPractice-module__practiceAgainButton_iM5_aa__ {
  margin-right: 16px;
}
