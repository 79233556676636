.WatchUnit-module__smallPauseBtn_kbfy0W__ {
  color: #f3f3f3;
  margin-left: 14px;
  font-size: 30px;
}

.WatchUnit-module__smallPauseBtn_kbfy0W__:hover {
  color: #fff;
  cursor: pointer;
}

.WatchUnit-module__videoCurrentTimeIcon_kbfy0W__ {
  background-color: #d9d9d9;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.WatchUnit-module__rightContainer_kbfy0W__ {
  flex-direction: column;
  display: flex;
}

.WatchUnit-module__instructionsContainer_kbfy0W__ {
  align-self: flex-start;
  padding-bottom: 100px;
}
