.RecordingControlRecording-module__container_0p2D8q__ {
  background: #292c2f;
  border-radius: 8px;
  margin-right: 10px;
  padding: 12px 18px;
}

.RecordingControlRecording-module__deleteContainer_0p2D8q__ {
  vertical-align: middle;
  cursor: pointer;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  display: inline-block;
  position: relative;
}

.RecordingControlRecording-module__deleteContainer_0p2D8q__:hover {
  background-color: #373737;
}

.RecordingControlRecording-module__deleteButton_0p2D8q__ {
  color: #fff;
  vertical-align: middle;
  font-size: 24px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RecordingControlRecording-module__pauseContainer_0p2D8q__ {
  vertical-align: middle;
  background-color: #e74b4a;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  margin-right: 36px;
  display: inline-block;
  position: relative;
}

.RecordingControlRecording-module__pauseContainer_0p2D8q__:hover {
  background-color: #ff7875;
}

.RecordingControlRecording-module__pauseContainer_0p2D8q__:active {
  background-color: #cf1323;
}

.RecordingControlRecording-module__pause_0p2D8q__ {
  color: #fff;
  border-radius: 1px;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RecordingControlRecording-module__timeElapsedContainer_0p2D8q__ {
  vertical-align: middle;
  margin-right: 17px;
  display: inline-block;
}

.RecordingControlRecording-module__timeElapsedText_0p2D8q__ {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.RecordingControlRecording-module__verticalDivider_0p2D8q__ {
  vertical-align: middle;
  background-color: #fff;
  width: 1px;
  height: 22px;
  margin-right: 20px;
  display: inline-block;
}

.RecordingControlRecording-module__submitButton_0p2D8q__ {
  vertical-align: middle;
}
