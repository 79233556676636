.toastui-editor, .toastui-editor-contents {
  font-family: 'Open Sans';

  h1, h2, h3, h4, h5, h6 {
    margin-top: 24px !important;
    margin-bottom: 16px !important;
    font-weight: 600 !important;
  }
  
  ul {
    margin-top: 7px !important;
    margin-bottom: 5px !important;
    padding-left: 18px !important;
    list-style: disc !important;
  }
  
  ol {
    padding-left: 18px !important;
    list-style-type: decimal !important;
  }
  
  ol > li::before {
    display: none !important;
  }

  ul > li::before {
    display: none !important;
  }
  
  p {
    font-size: 14px !important;
  }
  
  strong {
    font-weight: 600 !important;
  }

  table {
    border-collapse: collapse !important;
    width: 100% !important;
  }
  
  td {
    border: 1px solid black !important;
    text-align: left !important;
    padding: 8px !important;
  }
  
  th {
    border: 1px solid black !important;
    text-align: left !important;
    padding: 8px !important;
    font-weight: 600 !important;
    background-color: #f0f3fb !important;
    color: #000000 !important;

    p {
      color: #000000 !important;
    }
  }
}

.toastui-editor-defaultUI {
  border-radius: 8px !important;
  border-color: #d9d9d9 !important;
}

.toastui-editor-defaultUI-toolbar {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-color: #d9d9d9 !important;
}

.toastui-editor-ww-container {
  background-color: transparent !important;
}