.PracticeSubmission-module__container_6a-hdG__ {
  background-color: #fff;
  height: 100%;
  padding-bottom: 140px;
  overflow-y: scroll;
}

.PracticeSubmission-module__breadcrumbs_6a-hdG__, .PracticeSubmission-module__titleContainer_6a-hdG__ {
  margin-top: 37px;
}

.PracticeSubmission-module__submittedByContainer_6a-hdG__ {
  margin-top: 16px;
}

.PracticeSubmission-module__submittedBy_6a-hdG__ {
  font-size: 16px;
  font-weight: 600;
}

.PracticeSubmission-module__submittedOnContainer_6a-hdG__ {
  margin-top: 2px;
}

.PracticeSubmission-module__submittedOn_6a-hdG__ {
  color: #595959;
  font-size: 14px;
  font-weight: 300;
}

.PracticeSubmission-module__contentHierarchy_6a-hdG__ {
  flex-direction: row;
  gap: 54px;
  margin-top: 26px;
  display: flex;
}

.PracticeSubmission-module__topicHeader_6a-hdG__ {
  color: #595959;
  font-size: 16px;
  font-weight: 300;
}

.PracticeSubmission-module__topic_6a-hdG__ {
  font-size: 16px;
  font-weight: 600;
}

.PracticeSubmission-module__subtopicHeader_6a-hdG__ {
  color: #595959;
  font-size: 16px;
  font-weight: 300;
}

.PracticeSubmission-module__subtopic_6a-hdG__ {
  font-size: 16px;
  font-weight: 600;
}

.PracticeSubmission-module__practiceScenarioHeader_6a-hdG__ {
  color: #595959;
  font-size: 16px;
  font-weight: 300;
}

.PracticeSubmission-module__practiceScenario_6a-hdG__ {
  font-size: 16px;
  font-weight: 600;
}

.PracticeSubmission-module__link_6a-hdG__ {
  font-size: 16px;
  text-decoration: underline !important;
}

.PracticeSubmission-module__transcriptTitle_6a-hdG__ {
  margin-top: 47px;
}

.PracticeSubmission-module__transcript_6a-hdG__ {
  white-space: pre-wrap;
}

.PracticeSubmission-module__feedback_6a-hdG__ {
  margin-top: 48px;
}

.PracticeSubmission-module__learnerCommentsTitle_6a-hdG__ {
  margin-top: 41px;
}

.PracticeSubmission-module__ratingTitle_6a-hdG__ {
  color: #595959;
  margin-top: 14px;
  font-size: 16px;
}

.PracticeSubmission-module__positiveRatingContainer_6a-hdG__ {
  margin-top: 4px;
  font-size: 16px;
}

.PracticeSubmission-module__positiveFeedbackIcon_6a-hdG__ {
  background-color: #52c41a;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  display: inline-block;
}

.PracticeSubmission-module__negativeRatingContainer_6a-hdG__ {
  margin-top: 4px;
  font-size: 16px;
}

.PracticeSubmission-module__negativeFeedbackIcon_6a-hdG__ {
  background-color: #faae13;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  display: inline-block;
}

.PracticeSubmission-module__specificIssuesTitle_6a-hdG__ {
  color: #595959;
  margin-top: 28px;
  font-size: 16px;
}

.PracticeSubmission-module__specificIssues_6a-hdG__ {
  margin-top: 9px;
}

.PracticeSubmission-module__tag_6a-hdG__ {
  border-color: #d5d5d5;
  padding: 6px 8px;
  font-size: 14px;
}

.PracticeSubmission-module__detailsTitle_6a-hdG__ {
  color: #595959;
  margin-top: 28px;
  font-size: 16px;
}

.PracticeSubmission-module__details_6a-hdG__ {
  margin-top: 4px;
  font-size: 16px;
}
