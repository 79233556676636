.Unit-module__container_BdVSga__ {
  background-color: #fff;
  height: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
}

.Unit-module__sideNavContainer_BdVSga__ {
  background-color: #fffaec;
  max-width: 300px;
  height: 100%;
  overflow-y: auto;
}

.Unit-module__mainContent_BdVSga__ {
  flex-grow: 1;
  overflow-y: auto;
}

.Unit-module__allSubtopicsBtn_BdVSga__ {
  padding-left: 0;
}

.Unit-module__allSubtopicsLinkContainer_BdVSga__ {
  border-inline-end: 1px solid #0505050f;
  border-bottom: 1px solid #0505050f;
  padding: 24px 16px 16px 28px;
}

.Unit-module__subtopicTitleContainer_BdVSga__ {
  border-inline-end: 1px solid #0505050f;
  margin-left: 4px;
  padding: 18px 16px 14px 24px;
}

.Unit-module__sideNav_BdVSga__ {
  width: 100%;
  height: 100%;
}

.Unit-module__waitingIcon_BdVSga__ {
  background-color: #dedede;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.Unit-module__subtopicTitle_BdVSga__ {
  font-size: 16px;
  font-weight: 600;
}

.Unit-module__unitTitle_BdVSga__ {
  margin-bottom: 15px;
}
