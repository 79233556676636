.TopicDetails-module__container_c-3yWG__ {
  display: relative;
}

.TopicDetails-module__closeIcon_c-3yWG__ {
  color: #bfbfbf;
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 20px;
}

.TopicDetails-module__closeIcon_c-3yWG__:hover {
  color: #666;
}

.TopicDetails-module__titleContainer_c-3yWG__ {
  margin-bottom: 14px;
}

.TopicDetails-module__title_c-3yWG__ {
  font-size: 18px;
  font-weight: 700;
}

.TopicDetails-module__descriptionContainer_c-3yWG__ {
  margin-bottom: 32px;
}

.TopicDetails-module__subtopicTitleContainer_c-3yWG__ {
  margin-bottom: 13px;
}

.TopicDetails-module__subtopicsTitle_c-3yWG__ {
  font-size: 16px;
}

.TopicDetails-module__subtopicContainer_c-3yWG__ {
  border-bottom: 1px solid #d9d9d9;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  padding: 15px 6px;
  display: flex;
}

.TopicDetails-module__subtopicContainer_c-3yWG__:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.TopicDetails-module__subtopic_c-3yWG__ {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 300;
}

.TopicDetails-module__subtopicArrow_c-3yWG__ {
  color: #bbb;
  margin-right: 10px;
  font-size: 16px;
}

.TopicDetails-module__completeIcon_c-3yWG__ {
  margin-right: 10px;
  font-size: 28px;
}

.TopicDetails-module__waitingIcon_c-3yWG__ {
  background-color: #dedede;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
