.RecordingControlNotStarted-module__container_lc7eqW__ {
  background: #292c2f;
  border-radius: 8px;
  padding: 12px 18px;
}

.RecordingControlNotStarted-module__deleteContainer_lc7eqW__ {
  vertical-align: middle;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  display: inline-block;
  position: relative;
}

.RecordingControlNotStarted-module__deleteButton_lc7eqW__ {
  color: #9493a3;
  vertical-align: middle;
  font-size: 24px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RecordingControlNotStarted-module__pauseContainer_lc7eqW__ {
  vertical-align: middle;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  margin-right: 4px;
  display: inline-block;
  position: relative;
}

.RecordingControlNotStarted-module__pauseButton_lc7eqW__ {
  color: #9493a3;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RecordingControlNotStarted-module__timeElapsedContainer_lc7eqW__ {
  vertical-align: middle;
  margin-right: 17px;
  display: inline-block;
}

.RecordingControlNotStarted-module__timeElapsedText_lc7eqW__ {
  color: #9493a3;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.RecordingControlNotStarted-module__verticalDivider_lc7eqW__ {
  vertical-align: middle;
  background-color: #9493a3;
  width: 1px;
  height: 22px;
  margin-right: 20px;
  display: inline-block;
}
