.EditWatchUnit-module__container_T0P8UW__ {
  background-color: #fff;
  height: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
}

.EditWatchUnit-module__mainContent_T0P8UW__ {
  flex-grow: 1;
  padding-bottom: 300px;
  overflow-y: auto;
}

.EditWatchUnit-module__inputLabel_T0P8UW__ {
  margin-top: 0;
  margin-bottom: 7px;
}

.EditWatchUnit-module__inputDescription_T0P8UW__ {
  color: #595959;
  margin-bottom: 24px;
  font-size: 14px;
}

.EditWatchUnit-module__titleInput_T0P8UW__, .EditWatchUnit-module__instructionalVideoContainer_T0P8UW__ {
  margin-bottom: 64px;
}

.EditWatchUnit-module__generateVideoInstructions_T0P8UW__ {
  color: #595959;
  margin-bottom: 24px;
  font-size: 14px;
}

.EditWatchUnit-module__addSegmentBtn_T0P8UW__ {
  width: 100%;
  margin-bottom: 32px;
}

.EditWatchUnit-module__saveAndPreviewContainer_T0P8UW__ {
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 45px;
  display: flex;
}

.EditWatchUnit-module__saveStatusContainer_T0P8UW__ {
  align-items: center;
  display: flex;
}

.EditWatchUnit-module__changesSavedIcon_T0P8UW__ {
  margin-right: 10px;
  font-size: 24px;
}

.EditWatchUnit-module__changesSavedText_T0P8UW__, .EditWatchUnit-module__savingChangesText_T0P8UW__ {
  font-size: 16px;
}

.EditWatchUnit-module__savingChangesIcon_T0P8UW__ {
  margin-right: 10px;
  font-size: 24px;
}

.EditWatchUnit-module__videoGeneratingContainer_T0P8UW__ {
  align-items: center;
  gap: 10px;
  display: flex;
}

.EditWatchUnit-module__generatingLoadingSpinner_T0P8UW__ {
  color: #2e4a8b;
  font-size: 16px;
}

.EditWatchUnit-module__generatingText_T0P8UW__ {
  font-size: 16px;
}

.EditWatchUnit-module__videoGeneratedContainer_T0P8UW__ {
  align-items: center;
  gap: 10px;
  display: flex;
}

.EditWatchUnit-module__videoGeneratedIcon_T0P8UW__ {
  font-size: 20px;
}

.EditWatchUnit-module__videoGeneratedText_T0P8UW__ {
  font-size: 16px;
}

.EditWatchUnit-module__videoPlayerContainer_T0P8UW__ {
  max-width: 800px;
  margin-top: 16px;
}

.EditWatchUnit-module__videoGeneratedContainer_T0P8UW__ {
  margin-top: 32px;
}

.EditWatchUnit-module__learnerInstructionsEditor_T0P8UW__ {
  margin-bottom: 64px;
}
