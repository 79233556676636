.instructions-container {
  font-family: 'Open Sans';
}

.instructions-container h2 {
  margin-top: 0px;
  margin-bottom: 24px;
  font-weight: 600;
}

.instructions-container h4 {
  margin-bottom: 8px;
}

.instructions-container ul {
  margin-top: 7px;
  margin-bottom: 5px;
  padding-left: 18px;
}

.instructions-container ol {
  padding-left: 18px;
}

.instructions-container p {
  margin-top: 5px;
  margin-bottom: 25px;
}

.instructions-container strong {
  font-weight: 600;
}