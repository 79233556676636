.VideoPlayer-module__emptyStateContainer_hBOzya__ {
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  display: flex;
}

.VideoPlayer-module__emptyStateText_hBOzya__ {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
}

.VideoPlayer-module__container_hBOzya__ {
  width: 100%;
  position: relative;
}

.VideoPlayer-module__finishedOverlay_hBOzya__ {
  background-color: #000c;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.VideoPlayer-module__finishedTextContainer_hBOzya__ {
  text-align: left;
  margin-bottom: 100px;
}

.VideoPlayer-module__upNextText_hBOzya__ {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.VideoPlayer-module__nextCourseText_hBOzya__ {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.VideoPlayer-module__continueBtn_hBOzya__ {
  z-index: 10;
  margin-bottom: 30px;
}

.VideoPlayer-module__video_hBOzya__ {
  width: 100%;
  max-width: 100%;
  display: block;
}

.VideoPlayer-module__hidden_hBOzya__ {
  display: none;
}

.VideoPlayer-module__videoLoading_hBOzya__ {
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 432px;
  display: flex;
}

.VideoPlayer-module__overlay_hBOzya__ {
  pointer-events: none;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.VideoPlayer-module__playBtn_hBOzya__ {
  background-color: #fff;
  border-radius: 50%;
  width: 96px;
  height: 96px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VideoPlayer-module__playIcon_hBOzya__ {
  color: var(--primary-color);
  z-index: 10;
  font-size: 126px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VideoPlayer-module__playIcon_hBOzya__:hover {
  color: var(--primary-color-hover);
  cursor: pointer;
}

.VideoPlayer-module__playIcon_hBOzya__:active {
  color: var(--primary-color-active);
}

.VideoPlayer-module__initialLoadingSpinner_hBOzya__ {
  color: #fff;
  font-size: 48px;
}

.VideoPlayer-module__waitingSpinnerContainer_hBOzya__ {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VideoPlayer-module__waitingSpinner_hBOzya__ {
  color: #fff;
  font-size: 48px;
}

.VideoPlayer-module__controls_hBOzya__ {
  background-color: #343739;
  align-items: center;
  height: 40px;
  display: flex;
}

.VideoPlayer-module__muteBtn_hBOzya__ {
  color: #f3f3f3;
  margin-left: 12px;
  margin-right: 28px;
  font-size: 24px;
}

.VideoPlayer-module__muteBtn_hBOzya__:hover {
  color: #fff;
  cursor: pointer;
}

.VideoPlayer-module__soundBtn_hBOzya__ {
  color: #f3f3f3;
  margin-left: 16px;
  margin-right: 24px;
  font-size: 24px;
}

.VideoPlayer-module__soundBtn_hBOzya__:hover {
  color: #fff;
  cursor: pointer;
}

.VideoPlayer-module__timeContainer_hBOzya__ {
  flex-grow: 16;
  align-items: center;
  margin-right: 120px;
  display: flex;
}

.VideoPlayer-module__currentTimeContainer_hBOzya__ {
  text-align: center;
}

.VideoPlayer-module__timeText_hBOzya__ {
  color: #c5c5c5;
  font-size: 14px;
}

.VideoPlayer-module__timelineContainer_hBOzya__ {
  flex-grow: 16;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  position: relative;
}

.VideoPlayer-module__timeline_hBOzya__ {
  background-color: #d9d9d9;
  width: 100%;
  height: 2px;
}

.VideoPlayer-module__timeline_hBOzya__:hover {
  cursor: pointer;
}

.VideoPlayer-module__endTimeContainer_hBOzya__ {
  text-align: center;
}

.VideoPlayer-module__smallPlayBtn_hBOzya__ {
  color: #f3f3f3;
  margin-left: 14px;
  font-size: 30px;
}

.VideoPlayer-module__smallPlayBtn_hBOzya__:hover {
  color: #fff;
  cursor: pointer;
}

.VideoPlayer-module__replayBtn_hBOzya__ {
  color: #f3f3f3;
  margin-left: 14px;
  margin-right: 6px;
  font-size: 24px;
}

.VideoPlayer-module__replayBtn_hBOzya__:hover {
  color: #fff;
  cursor: pointer;
}
