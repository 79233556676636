.Overview-module__container_Cq2dHa__ {
  background-color: #fff;
  height: 100%;
}

.Overview-module__titleContainer_Cq2dHa__ {
  border-bottom: 1px solid #d9d9d9;
}

.Overview-module__title_Cq2dHa__ {
  margin-bottom: 21px;
}

.Overview-module__mainContentContainer_Cq2dHa__ {
  height: 100%;
}

.Overview-module__topicCardsContainer_Cq2dHa__ {
  flex: 1;
  height: 100%;
  padding-bottom: 157px;
  overflow: hidden scroll;
}

.Overview-module__detailsContainer_Cq2dHa__ {
  border-left: 1px solid #d9d9d9;
  flex: 1;
  height: 100%;
  padding-top: 26px;
  padding-bottom: 200px;
  overflow: hidden scroll;
}

.Overview-module__onlyOneDetailsContainer_Cq2dHa__ {
  padding-top: 26px;
}
