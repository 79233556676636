.BrowserPermissionsDeniedModal-module__content_8z5P7a__ {
  padding: 32px 40px !important;
}

.BrowserPermissionsDeniedModal-module__footer_8z5P7a__ {
  text-align: start !important;
}

.BrowserPermissionsDeniedModal-module__title_8z5P7a__ {
  margin-top: 15px;
}

.BrowserPermissionsDeniedModal-module__body_8z5P7a__ {
  margin-bottom: 43px;
}

.BrowserPermissionsDeniedModal-module__cancelButton_8z5P7a__ {
  display: none;
}

.BrowserPermissionsDeniedModal-module__iconsContainer_8z5P7a__ {
  gap: 10px;
  margin-bottom: 18px;
  display: flex;
}

.BrowserPermissionsDeniedModal-module__lockedAudioIcon_8z5P7a__ {
  position: relative;
}

.BrowserPermissionsDeniedModal-module__audioIcon_8z5P7a__, .BrowserPermissionsDeniedModal-module__videoCameraIcon_8z5P7a__ {
  color: #8c8c8c;
  font-size: 36px;
}

.BrowserPermissionsDeniedModal-module__lockedVideoIcon_8z5P7a__ {
  position: relative;
}

.BrowserPermissionsDeniedModal-module__lockContainer_8z5P7a__ {
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  display: flex;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.BrowserPermissionsDeniedModal-module__lockIcon_8z5P7a__ {
  color: #ff4d4a;
  font-size: 16px;
}

.BrowserPermissionsDeniedModal-module__okButton_8z5P7a__ {
  margin-left: 0 !important;
}
