.App-module__header_Vqq1ea__ {
  z-index: 1000;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
}

.App-module__logoContainer_Vqq1ea__ {
  height: 100%;
  margin-right: 10px;
  padding: 8px;
}

.App-module__logo_Vqq1ea__ {
  height: 100%;
}

.App-module__container_Vqq1ea__ {
  background-color: #fff;
}
