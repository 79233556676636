.GrantDevicePermissionsModal-module__content_hM8VlG__ {
  padding: 32px 40px !important;
}

.GrantDevicePermissionsModal-module__footer_hM8VlG__ {
  text-align: start !important;
}

.GrantDevicePermissionsModal-module__cancelButton_hM8VlG__ {
  display: none;
}

.GrantDevicePermissionsModal-module__grantPermissions_hM8VlG__ .GrantDevicePermissionsModal-module__iconContainer_hM8VlG__ {
  gap: 10px;
  margin-bottom: 18px;
  display: flex;
}

.GrantDevicePermissionsModal-module__grantPermissions_hM8VlG__ .GrantDevicePermissionsModal-module__audioIcon_hM8VlG__, .GrantDevicePermissionsModal-module__grantPermissions_hM8VlG__ .GrantDevicePermissionsModal-module__videoCameraIcon_hM8VlG__ {
  color: #8c8c8c;
  font-size: 36px;
}

.GrantDevicePermissionsModal-module__grantPermissions_hM8VlG__ .GrantDevicePermissionsModal-module__title_hM8VlG__ {
  margin-top: 15px;
}

.GrantDevicePermissionsModal-module__grantPermissions_hM8VlG__ .GrantDevicePermissionsModal-module__body_hM8VlG__ {
  margin-bottom: 43px;
}

.GrantDevicePermissionsModal-module__okButton_hM8VlG__ {
  margin-left: 0 !important;
}

.GrantDevicePermissionsModal-module__clickAccept_hM8VlG__ .GrantDevicePermissionsModal-module__titleContainer_hM8VlG__ {
  margin-top: 15px;
}

.GrantDevicePermissionsModal-module__clickAccept_hM8VlG__ .GrantDevicePermissionsModal-module__title1_hM8VlG__ {
  font-size: 16px;
}

.GrantDevicePermissionsModal-module__clickAccept_hM8VlG__ .GrantDevicePermissionsModal-module__title2_hM8VlG__ {
  font-size: 16px;
  font-weight: 600;
}

.GrantDevicePermissionsModal-module__okButtonHidden_hM8VlG__ {
  display: none;
}
