.PracticeIntroVideo-module__videoLoading_uKYgNG__ {
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 432px;
  margin-bottom: 20px;
  display: flex;
}

.PracticeIntroVideo-module__videoContainer_uKYgNG__ {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.PracticeIntroVideo-module__loading_uKYgNG__ {
  color: #fff;
  font-size: 48px;
}

.PracticeIntroVideo-module__video_uKYgNG__ {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.PracticeIntroVideo-module__hidden_uKYgNG__ {
  display: none;
}
