.Card-module__container_T5rgPa__ {
  margin-top: 26px;
}

.Card-module__unselectedContainer_T5rgPa__ {
  border: 1px solid #d9d9d9;
  padding: 23px 31px;
}

.Card-module__selectedContainer_T5rgPa__ {
  border-top: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  border-left: 2px solid var(--secondary-color);
  border-right: 2px solid var(--secondary-color);
  padding: 22px 30px;
}

.Card-module__container_T5rgPa__:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.Card-module__title_T5rgPa__ {
  margin-top: 0;
}

.Card-module__tag_T5rgPa__ {
  margin-top: 14px;
}

.Card-module__description_T5rgPa__ {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
