.SetInitialPassword-module__container_NGKrpG__ {
  box-sizing: border-box;
  place-content: center;
  height: 100vh;
  padding-top: 100px;
  display: flex;
  overflow-y: auto;
}

.SetInitialPassword-module__resetPasswordContainer_NGKrpG__ {
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  padding-bottom: 20px;
  display: flex;
}

.SetInitialPassword-module__instructLogoContainer_NGKrpG__ {
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.SetInitialPassword-module__errorAlert_NGKrpG__ {
  margin-bottom: 26px;
}

.SetInitialPassword-module__instructLogo_NGKrpG__ {
  width: 50px;
}

.SetInitialPassword-module__titleContainer_NGKrpG__ {
  margin-bottom: 24px;
}

.SetInitialPassword-module__msgContainer_NGKrpG__ {
  text-align: center;
  margin-bottom: 45px;
}

.SetInitialPassword-module__title_NGKrpG__ {
  text-align: center;
  margin-top: 0;
}

.SetInitialPassword-module__form_NGKrpG__ {
  margin-bottom: 30px;
}

.SetInitialPassword-module__confirmPasswordFormItem_NGKrpG__ {
  margin-bottom: 41px;
}

.SetInitialPassword-module__submitBtn_NGKrpG__ {
  width: 100%;
}

.SetInitialPassword-module__termsOfServiceContainer_NGKrpG__ {
  padding-bottom: 20px;
}

.SetInitialPassword-module__termsOfService_NGKrpG__ {
  color: #595959;
}
