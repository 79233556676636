.OSPermissionsDeniedModal-module__content_5vtCaG__ {
  padding: 32px 40px !important;
}

.OSPermissionsDeniedModal-module__footer_5vtCaG__ {
  text-align: start !important;
}

.OSPermissionsDeniedModal-module__title_5vtCaG__ {
  margin-top: 15px;
}

.OSPermissionsDeniedModal-module__body_5vtCaG__ {
  margin-bottom: 30px;
}

.OSPermissionsDeniedModal-module__cancelButton_5vtCaG__ {
  display: none;
}

.OSPermissionsDeniedModal-module__okButton_5vtCaG__ {
  margin-left: 0 !important;
}

.OSPermissionsDeniedModal-module__macInstructionsContainer_5vtCaG__ {
  align-items: flex-start;
  gap: 7px;
  margin-bottom: 20px;
  display: flex;
}

.OSPermissionsDeniedModal-module__macIcon_5vtCaG__ {
  color: #8c8c8c;
  font-size: 30px;
}

.OSPermissionsDeniedModal-module__macInstructions_5vtCaG__ {
  flex-grow: 1;
}

.OSPermissionsDeniedModal-module__windowsInstructionsContainer_5vtCaG__ {
  align-items: flex-start;
  gap: 7px;
  margin-bottom: 45px;
  display: flex;
}

.OSPermissionsDeniedModal-module__windowsIcon_5vtCaG__ {
  color: #8c8c8c;
  font-size: 30px;
}

.OSPermissionsDeniedModal-module__windowsInstructions_5vtCaG__ {
  flex-grow: 1;
}

.OSPermissionsDeniedModal-module__link_5vtCaG__ {
  text-decoration: underline !important;
}
