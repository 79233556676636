.PracticeSubmitModal-module__bodyContainer1_KL2koG__ {
  margin-bottom: 25px;
}

.PracticeSubmitModal-module__bodyContainer2_KL2koG__ {
  margin-bottom: 28px;
}

.PracticeSubmitModal-module__submitConfirmationContainer_KL2koG__ {
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  display: flex;
}

.PracticeSubmitModal-module__checkMark_KL2koG__ {
  color: #52c41a;
  font-size: 24px;
}

.PracticeSubmitModal-module__submitConfirmationMsg_KL2koG__ {
  font-size: 16px;
  font-weight: 600;
}
