.EditVideoSegment-module__contentContainer_0EaXKW__ {
  gap: 24px;
  margin-bottom: 3px;
  display: flex;
}

.EditVideoSegment-module__container_0EaXKW__ {
  margin-bottom: 38px;
}

.EditVideoSegment-module__title_0EaXKW__ {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 600;
}

.EditVideoSegment-module__scriptLabel_0EaXKW__ {
  margin-bottom: 7px;
  font-size: 14px;
}

.EditVideoSegment-module__scriptTextarea_0EaXKW__ {
  flex-grow: 2;
}

.EditVideoSegment-module__addSlide_0EaXKW__ {
  flex-grow: 1;
  width: 80%;
}

.EditVideoSegment-module__addSlide_0EaXKW__ div {
  width: 100% !important;
}

.EditVideoSegment-module__slidePreviewContainer_0EaXKW__ {
  flex-grow: 1;
  width: 80%;
  position: relative;
}

.EditVideoSegment-module__slidePreview_0EaXKW__ {
  width: 100%;
}

.EditVideoSegment-module__slideDeleteOverlay_0EaXKW__ {
  visibility: hidden;
  background-color: #0009;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.EditVideoSegment-module__slidePreviewContainer_0EaXKW__:hover .EditVideoSegment-module__slideDeleteOverlay_0EaXKW__ {
  visibility: visible;
}

.EditVideoSegment-module__deleteSlideContent_0EaXKW__ {
  z-index: 1;
  opacity: 1;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.EditVideoSegment-module__deleteSlideContent_0EaXKW__:hover {
  cursor: pointer;
}

.EditVideoSegment-module__deleteIcon_0EaXKW__ {
  color: #fff;
  font-size: 22px;
}

.EditVideoSegment-module__deleteText_0EaXKW__ {
  color: #fff;
  font-size: 16px;
}

.EditVideoSegment-module__slideDeletingOverlay_0EaXKW__ {
  background-color: #0009;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.EditVideoSegment-module__deletingSlideContent_0EaXKW__ {
  z-index: 1;
  opacity: 1;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.EditVideoSegment-module__deletingSpinner_0EaXKW__ {
  color: #fff;
}

.EditVideoSegment-module__deleteBtn_0EaXKW__ {
  padding: 0;
}
