.ReviewFeedback-module__container_pM7fvG__ {
  margin-top: 20px;
  margin-bottom: 80px;
}

.ReviewFeedback-module__emptyContainer_pM7fvG__ {
  margin-top: 40px;
}

.ReviewFeedback-module__feedbackQualityContainer_pM7fvG__ {
  justify-content: left;
  align-items: center;
  gap: 10px;
  display: flex;
}

.ReviewFeedback-module__feedbackQualityMsg_pM7fvG__ {
  margin-right: 12px;
  font-size: 16px;
}

.ReviewFeedback-module__tagsTitleContainer_pM7fvG__ {
  margin-bottom: 15px;
}

.ReviewFeedback-module__tagsTitle_pM7fvG__ {
  font-size: 16px;
}

.ReviewFeedback-module__tagsContainer_pM7fvG__ {
  max-width: 500px;
}

.ReviewFeedback-module__tagsButtonsContainer_pM7fvG__ {
  justify-content: space-between;
  display: flex;
}

.ReviewFeedback-module__rightTagButtonsContainer_pM7fvG__ {
  gap: 10px;
  display: flex;
}

.ReviewFeedback-module__tag_pM7fvG__ {
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 6px 8px;
  font-size: 14px;
}

.ReviewFeedback-module__unselectedTag_pM7fvG__ {
  border-color: #d5d5d5;
}

.ReviewFeedback-module__detailsContainer_pM7fvG__ {
  max-width: 500px;
}

.ReviewFeedback-module__detailsTitleContainer_pM7fvG__ {
  margin-bottom: 15px;
}

.ReviewFeedback-module__detailsTitle_pM7fvG__ {
  font-size: 16px;
}

.ReviewFeedback-module__details_pM7fvG__ {
  margin-bottom: 30px;
}

.ReviewFeedback-module__detailsButtonsContainer_pM7fvG__ {
  justify-content: space-between;
  display: flex;
}

.ReviewFeedback-module__rightDetailsButtonsContainer_pM7fvG__ {
  gap: 10px;
  display: flex;
}

.ReviewFeedback-module__confirmationPositiveContainer_pM7fvG__ {
  max-width: 500px;
}

.ReviewFeedback-module__confirmationPositiveCheckContainer_pM7fvG__ {
  margin-bottom: 18px;
}

.ReviewFeedback-module__confirmationPositiveCheck_pM7fvG__ {
  color: #52c41a;
  font-size: 38px;
}

.ReviewFeedback-module__confirmationPositiveText_pM7fvG__ {
  font-size: 16px;
}

.ReviewFeedback-module__confirmationNegativeContainer_pM7fvG__ {
  max-width: 500px;
}

.ReviewFeedback-module__confirmationNegativeCheckContainer_pM7fvG__ {
  margin-bottom: 18px;
}

.ReviewFeedback-module__confirmationNegativeCheck_pM7fvG__ {
  color: #52c41a;
  font-size: 38px;
}

.ReviewFeedback-module__confirmationNegativeTopTextContainer_pM7fvG__ {
  align-items: center;
  margin-bottom: 18px;
  display: inline-flex;
}

.ReviewFeedback-module__confirmationNegativeTopText_pM7fvG__ {
  margin-right: 6px;
  font-size: 16px;
}

.ReviewFeedback-module__confirmationNegativeBottomText_pM7fvG__ {
  font-size: 16px;
}
