.PracticeInstructions-module__buttonContainer_vFxvja__ {
  justify-content: left;
  margin-top: 55px;
  margin-bottom: 100px;
  display: flex;
}

.PracticeInstructions-module__rewatchVideoButton_vFxvja__ {
  margin-right: 24px;
}
