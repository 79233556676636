.ResetPassword-module__container_-0_6aa__ {
  box-sizing: border-box;
  place-content: center;
  height: 100%;
  padding-top: 100px;
  display: flex;
  overflow-y: auto;
}

.ResetPassword-module__instructLogoContainer_-0_6aa__ {
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.ResetPassword-module__resetPasswordContainer_-0_6aa__ {
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  display: flex;
}

.ResetPassword-module__errorAlert_-0_6aa__ {
  margin-bottom: 26px;
}

.ResetPassword-module__instructLogo_-0_6aa__ {
  width: 50px;
}

.ResetPassword-module__titleContainer_-0_6aa__ {
  margin-bottom: 24px;
}

.ResetPassword-module__msgContainer_-0_6aa__ {
  text-align: center;
  margin-bottom: 45px;
}

.ResetPassword-module__title_-0_6aa__ {
  text-align: center;
  margin-top: 0;
}

.ResetPassword-module__form_-0_6aa__ {
  margin-bottom: 100px;
}

.ResetPassword-module__confirmPasswordFormItem_-0_6aa__ {
  margin-bottom: 41px;
}

.ResetPassword-module__submitBtn_-0_6aa__ {
  width: 100%;
}
