.ResetPasswordSuccess-module__container_owad3q__ {
  box-sizing: border-box;
  place-content: center;
  height: 100%;
  padding-top: 100px;
  display: flex;
  overflow-y: auto;
}

.ResetPasswordSuccess-module__resetPasswordSuccessContainer_owad3q__ {
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  display: flex;
}

.ResetPasswordSuccess-module__checkMark_owad3q__ {
  color: #52c41a;
  margin-bottom: 16px;
  font-size: 60px;
}

.ResetPasswordSuccess-module__title_owad3q__ {
  margin-top: 0;
  margin-bottom: 16px;
}

.ResetPasswordSuccess-module__msg_owad3q__ {
  margin-bottom: 34px;
}
