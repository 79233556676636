.ForgotPassword-module__container_dIcHwq__ {
  box-sizing: border-box;
  place-content: center;
  height: 100%;
  padding-top: 100px;
  display: flex;
  overflow-y: auto;
}

.ForgotPassword-module__instructLogoContainer_dIcHwq__ {
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.ForgotPassword-module__forgotPasswordContainer_dIcHwq__ {
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  display: flex;
}

.ForgotPassword-module__instructLogo_dIcHwq__ {
  width: 50px;
}

.ForgotPassword-module__titleContainer_dIcHwq__ {
  margin-bottom: 24px;
}

.ForgotPassword-module__title_dIcHwq__ {
  text-align: center;
  margin-top: 0;
}

.ForgotPassword-module__msgContainer_dIcHwq__ {
  text-align: center;
  margin-bottom: 45px;
}

.ForgotPassword-module__form_dIcHwq__ {
  margin-bottom: 100px;
}

.ForgotPassword-module__emailInput_dIcHwq__ {
  margin-bottom: 41px;
}

.ForgotPassword-module__submitBtn_dIcHwq__ {
  width: 100%;
}
