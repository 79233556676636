.LearnerPracticeSubmissions-module__container_2ArVWq__ {
  background-color: #fff;
  height: 100%;
  padding-bottom: 140px;
  overflow-y: scroll;
}

.LearnerPracticeSubmissions-module__breadcrumbs_2ArVWq__, .LearnerPracticeSubmissions-module__learnerNameContainer_2ArVWq__ {
  margin-top: 37px;
}

.LearnerPracticeSubmissions-module__emailContainer_2ArVWq__ {
  color: #595959;
  font-size: 16px;
  font-weight: 400;
}

.LearnerPracticeSubmissions-module__topicContainer_2ArVWq__ {
  margin-top: 60px;
}

.LearnerPracticeSubmissions-module__subtopicContainer_2ArVWq__ {
  margin-top: 33px;
}

.LearnerPracticeSubmissions-module__submissionTag_2ArVWq__:hover {
  cursor: pointer;
}
