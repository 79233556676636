.AllLearners-module__container_BbZGNa__ {
  background-color: #fff;
  height: 100%;
  padding-bottom: 140px;
  overflow-y: scroll;
}

.AllLearners-module__titleContainer_BbZGNa__ {
  margin-top: 96px;
}
