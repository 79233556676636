.ForgotPasswordConfirmation-module__container_uirYda__ {
  box-sizing: border-box;
  place-content: center;
  height: 100%;
  padding-top: 100px;
  display: flex;
  overflow-y: auto;
}

.ForgotPasswordConfirmation-module__forgotPasswordConfirmationContainer_uirYda__ {
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  display: flex;
}

.ForgotPasswordConfirmation-module__checkMark_uirYda__ {
  color: #52c41a;
  margin-bottom: 16px;
  font-size: 60px;
}

.ForgotPasswordConfirmation-module__title_uirYda__ {
  margin-top: 0;
  margin-bottom: 16px;
}
