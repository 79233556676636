.PracticeFeedback-module__fullContainer_BYVWuW__ {
  flex-flow: column;
  height: 100%;
  display: flex;
}

.PracticeFeedback-module__transcriptContainer_BYVWuW__ {
  margin-bottom: 40px;
}

.PracticeFeedback-module__yourPracticeSessionTitle_BYVWuW__ {
  margin-top: 5px;
}

.PracticeFeedback-module__statusIndicatorContainer_BYVWuW__ {
  margin-bottom: 30px;
}

.PracticeFeedback-module__statusHeader_BYVWuW__ {
  font-size: 16px;
  font-weight: 600;
}

.PracticeFeedback-module__notSubmittedStatus_BYVWuW__ {
  color: #595959;
  font-size: 16px;
  font-weight: 700;
}

.PracticeFeedback-module__submittedStatus_BYVWuW__ {
  color: #52c41a;
  font-size: 16px;
  font-weight: 700;
}

.PracticeFeedback-module__transcriptTitle_BYVWuW__ {
  margin-top: 5px;
}

.PracticeFeedback-module__transcriptText_BYVWuW__ {
  white-space: pre-wrap;
}

.PracticeFeedback-module__feedbackContainer_BYVWuW__ {
  margin-bottom: 20px;
}

.PracticeFeedback-module__separatingLine_BYVWuW__ {
  background-color: #ddd;
  width: 100%;
  height: 1px;
}

.PracticeFeedback-module__separatingLineInvisible_BYVWuW__ {
  width: 100%;
  height: 1px;
}

.PracticeFeedback-module__nextActionBtnContainer_BYVWuW__ {
  gap: 30px;
  margin-bottom: 160px;
  display: flex;
}
